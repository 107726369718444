<template>
  <div class="homePublic" style="margin-top: -120px">
    <section class="hero is-medium is-main is-darkNO">
      <div class="hero-body" style="padding: 0; margin: 0; padding-top: 60px; padding-bottom: 4rem">
        <div class="container" style="padding: 20px; margin-top: 50px">
          <div class="columns is-vcentered">
            <div class="column">
              <br />
              <br />

              <p class="title is-3">
                <!--   
-->
                Launch a custom GPT in 48h
              </p>
              <p class="subtitle is-5">Secure your turf and unlock new audiences on the ChatGPT platform.</p>

              <div style="max-width: 500px">
                <b-button type="is-dark" style="font-weight: bold" @click="scrollToDiv">Get Started</b-button>

                <waitlist source="agency" v-if="false" />
                <!--       -->
              </div>
              <!-- 
          <b-button native-type="submit" type="  is-dark" style="text-transform: uppercase; letter-spacing: 1px">
            TRY IT NOW
          </b-button>
        -->
              <br />
              <em style="color: black; opacity: 0.2">
                <!-- 
            Get early access to OnlyBots beta.<br />-->
                <!-- 
            OnlyBots beta is invite only.-->
              </em>

              <!--   
            The first AI creators community. Invite only
            The first AI creators community. Invite only<em style="color: black; opacity: 0.2">Join the first AI creators community </em>  <qrform /> -->
            </div>

            <div class="column is-3">
              <!-- -->
              <img src="/img/gpt/landing/chatbot1.png" style="max-width: 300px" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <br />
    <br />

    <div class="section">
      <div class="container c1200 promoMenu" style="max-width: 800px !important">
        <div class="" style="text-align: center">
          <p class="title is-6 label">
            <!--  That's what we do -->
            We 🖤 building AI agents
          </p>
          <p class="title is-1">
            <!--
            Working with us
          -->
            Let's work together
          </p>
          <p class="subtitle is-5">
            QualityGPTs is an agency specializing in custom GPTs. We help brands and organizations maximize their use of
            the ChatGPT platform.
          </p>
          <br />
        </div>

        <div
          v-for="(item, index) in julyFeatures"
          :key="index"
          class="columns alterGrid is-vcentered"
          :class="{ 'reverse-columns-desktop': !(index % 2) }"
        >
          <div class="column is-6" style="padding-bottom: 0">
            <img :src="item.image" style="max-height: 300px; height: 30vw" />
          </div>
          <div class="column is-6">
            <div class="cardNO cardCity">
              <div>
                <div class="card-contentNO">
                  <!--
                  <span class="icon is-large is-left">
                    <i :class="'fal fa-' + item.icon"></i>
                  </span>  -->
                  <p class="title is-6 label">{{ item.label }}</p>
                  <p class="title is-2">{{ item.title }}</p>
                  <p class="subtitleNO desc">{{ item.desc }}</p>
                </div>
              </div>
              <!-- 
              <footer class="card-footer">
                <p class="card-footer-item">
                  <a href="/quote" class="button is-link bold">GET STARTED</a>
                  <span v-if="item.demoUrl"
                    >&nbsp; &nbsp;
                    <a :href="item.demoUrl" target="_blank" class="button is-light">Demo</a>
                  </span>
                </p>
              </footer> -->
            </div>
          </div>
          <br /><br /><br />
        </div>
      </div>
    </div>
    <!--  
    <div class="section">
      <div class="container c1200 promoMenuNO" style="">
        <div style="text-align: center">
          <div style="">
            <appStoreBtns />
          </div>
          <br />

          <h2 class="title is-2">Fast, helpful and fun</h2>

          <h2 class="subtitle is-4">Learn new things, get things done, and talk with hundreds of different AI</h2>
          <br />
        </div>

        <bot-list :bots="bots" :includeLinks="false" :page-size="16" :key="543534"></bot-list>

        <b-tabs v-model="selectedBotCat" expanded>
          <b-tab-item :label="i.title" v-for="(i, index) in botCats" :key="index + 'f666ds'"> </b-tab-item>
        </b-tabs>

        <div v-if="botCats && botCats.length && botCats[selectedBotCat] && botCats[selectedBotCat].bots">
          <bot-list
            :bots="botCats[selectedBotCat].bots"
            :includeLinks="false"
            :page-size="16"
            :key="selectedBotCat + 'fdshui'"
          ></bot-list>
        </div>

        <div v-show="false">
          <promoMenu />
        </div>
      </div>
      <hr />
    </div>


        
          -->

    <div class="section pricing" id="pricing">
      <div style="text-align: center">
        <h2 class="title is-3">
          <!--

  Professional GPTs, good price.

            -->
          Accurate, reliable, and affordable.
        </h2>
        <h2 class="subtitle is-4" style="max-width: 780px; text-align: center; margin: auto">
          All bundles include documentation, source materials, friendly support, all core features, and generous usage
          limits.
        </h2>
        <br />
      </div>
      <div class="container c1200 pricing">
        <pricing />

        <br />
        <div class="columns">
          <div class="column">
            <div class="box">
              <p class="title is-2">
                <!--     <qrform /> 
                Be a part of the AI revolution.-->
                Not sure?
                <br />
                Let's talk 😊
              </p>
              <a href="https://superpeer.com/menard/-/gpt-demo" style="font-weight: bold" target="_blank">
                <b-button type="is-dark" style="font-weight: bold">Book a Demo</b-button>
              </a>
              <a
                href="mailto:team@qualitygpts.com?subject=Custom GPT project"
                class="button is-dark is-outlined"
                style="font-weight: bold"
                target="_blank"
              >
                <span class="icon">
                  <i class="fal fa-envelope"></i>
                </span>
                <span>Discuss your project</span>
              </a>
              <!-- 
              <waitlist source="agency" />
               -->
            </div>
          </div>
          <div class="column"></div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container c1200">
        <!--      -->
        <p class="title is-3">Infinite use cases 💥</p>
        <p class="subtitle is-5">GPTs are used for various tasks accross many industries.</p>

        <div class="columns is-multiline">
          <div class="column is-4" v-for="feature in keyFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content" v-html="feature.description"></div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <hr />

        <div class="columns is-vcentered">
          <div class="column is-7">
            <h3 class="title id-4">Affiliate Program</h3>
            <p class=" ">
              Help us on our mission to build the best AI tools. Promote QualityGPTs on your social media, podcast, or
              blog, and receive a

              <strong> 20% commission</strong>.

              <!-- 
              We're looking for creators to establish the foundation of the OnlyBots platform. Be amongst the first
              brands & creators to join; establish your presence on the platform.
              -->
            </p>
            <p>
              <br />
              <a
                href="mailto:team@qualitygpts.com?subject=QualityGPTs affiliate program&body=Hi! I'd like to promote QualityGPT and join the affiliate program."
                class="button is-dark is-outlined"
                style="font-weight: bold"
                target="_blank"
              >
                <span class="icon">
                  <i class="fal fa-envelope"></i>
                </span>
                <span>Get in touch</span>
              </a>
            </p>
          </div>
          <div class="column">
            <img
              class="multiply"
              src="/img/gpt/landing/mega1.png"
              style="max-width: 300px; margin: auto; display: block"
            />
          </div>
        </div>

        <!-- eo col-->
      </div>
    </div>

    <div class="section" id=" ">
      <div class="container c1200" style="text-align: left">
        <br />
        <br />

        <!--
        <br />
        <div style="">
          <appStoreBtns />
        </div>
        <br />
  -->
        <br />

        <br />
        <p class=" ">© {{ new Date().getFullYear() }}. <strong>QualityGPTs</strong> - Made with ❤️ by real humans</p>
      </div>
    </div>

    <!--  
    <div class="section">
      <div class="container c1200 guide">
        <guide />
      </div>
    </div>

    <theFooter />
     -->
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";
import qrform from "@/components/re/qrform.vue";
import BotList from "@/components/BotList.vue";

import guide from "@/components/re/guide.vue";
import promoMenu from "@/components/re/promoMenu.vue";

import promoBlock from "@/components/re/promoBlock.vue";

import appStoreBtns from "@/components/brochure/appStoreBtns.vue";
//import pricing
import pricing from "@/components/pricing.vue";
const keyFeatures = [
  {
    title: "Brand Marketing 🌟",
    description:
      "Connect with your audience on ChatGPT. Use AI to communicate your brand's message in a way that's engaging and true to your voice.",
  },
  {
    title: "Automated Support 🤖",
    description:
      "Transform customer support with AI. Provide quick, accurate responses that maintain the right tone, improving customer satisfaction.",
    score: 9,
  },

  {
    title: "Ecommerce Integration 🛒",
    description:
      "Connect your product catalog to AI, enabling personalized suggestions and real-time inventory updates during customer chats.",
  },

  {
    title: "Internal Tools 🛠️",
    description:
      "Improve team efficiency with AI-enhanced tools. Facilitate smoother internal communications and processes.",
  },

  {
    title: "Appointment Booking  📅",
    description:
      "Streamline appointment scheduling with an AI assistant, reducing administrative workload and improving customer experience.",
    score: 7,
  },

  {
    title: "Education 📚",
    description:
      "Tailor chatbots to your curriculum. Facilitate engaging and relevant student interactions that complement their learning experience.",
  },

  {
    title: "Personality Clone 👥",
    description:
      "Create a digital version of yourself. Your chatbot will communicate and create content with your personal style, maintaining your presence consistently and engagning your audience in new ways.",
  },

  {
    title: "Content Creation  📝",
    description:
      "Automate content writing, generate creative social media post ideas, and respond effectively to online reviews, enhancing digital presence and engagement.",
    score: 8,
  },
  {
    title: "Workflow automations  🚀",
    description:
      "Custom AI tools designed for your specific business processes. Automating processes with GPTs has a great ROI.",
    score: 9,
  },
  /*  */

  /*  
  {
    title: "Homework Helper Bot 📚",
    description:
      "AI chatbots that assist students with homework, offering explanations, resources, and study tips in various subjects.",
    score: 8,
  },
  {
    title: "Workflow automations🚀",
    description:
      "Custom AI tools designed for your specific business processes. Streamline your workflow with solutions that fit seamlessly into your operations.",
  },

  {
    title: "Accurate Data 🔍",
    description:
      "Rely on precise and coherent AI interactions. Our approach minimizes errors and enhances the reliability of information provided.",
  },
   */
];

/*
"Pioneering an AI internet for all
"	OnlyBots aim to unlock the transformative power of AI, fostering a new era of creativity, innovation, and unprecedented growth.*/

const julyFeatures = [
  // ING: https://icons8.com/illustrations/style--3d-plastic-people
  /*
  {
    label: "AGENCY", //"TECH / starting point / PRODUCT",
    title: "Let's work together",
    desc: " QualityGPTs is an agency specialized in custom GPTs. We help brands and organizations get the most out of  the ChatGPT platform. ",
    image: "/img/gpt/landing/chatbot1.png",
  },
*/
  {
    label: "NOW", //"TECH / starting point / PRODUCT",
    title: "Don't miss the train",
    desc: "Now is the perfect time to launch on GPT store. Join the conversation and start learning. ",
    // image: "/img/gpt/landing/bolt1.png",
    image: "/img/gpt/landing/wait1.png",
  },
  {
    label: "USER EXPERIENCE",
    title: "Don’t look bad",
    // desc: " Precisely shape your GPT behviors and functionality. We ensure accurate and coherent interactions, free from common AI pitfalls..",

    desc: "Precisely shape your GPT's behavior and functionality. We ensure accurate and coherent interactions, steering clear of common AI pitfalls.",
    //image: "/img/gpt/landing/star1.png",
    image: "/img/gpt/landing/robot3.png",
  },
  {
    label: "EXPERT TEAM",
    title: "Don’t waste time",
    // fine-tuning a ChatBot requires endless trial and errors
    desc: "While drafting a basic GPT quickly is possible, non-experts often fall into a cycle of endless trial and error. Our team excels in programming LLMs, preparing data effectively, and coding advanced integrations.",
    image: "/img/gpt/landing/wrench1.png",
  },
  {
    label: "ADVANCED FEATURES",
    title: "Go Further",
    // Revolutionize your -> Boost
    desc: "Bring your product data, connect your APIs, and let us build custom features that will truly delight your customers.",
    image: "/img/gpt/landing/boom1.png",
  },
  /*
  {
    label: "EARN",
    title: "Empowering AI creators",
    desc: "Unleash your creativity to tap into the AI economy. Monetize captivating AI content, launch market-ready chatbots or design specialized tools. No coding skills? No problem!",
    image: "/img/ilu/3d/money.png",
  }
  {
    label: "SEARCH",
    title: "Knowledge, redefined.",
    desc: "Revolutionize your search - familiar UI, AI precision.",
    image: "/img/ilu/cherry/.png",
  },
  {
    label: "Navigate the future of information retrieval -",
    title: "it's more than just finding, it's truly understanding",
    desc: "",
    image: "/img/ilu/cherry/.png",
  },*/
  /*
  {
    label: "EARN",
    title: "Empowering AI creators",
    desc: "Unleash your creativity to tap into the AI economy. Monetize captivating AI content, launch market-ready chatbots or design specialized tools. No coding skills? No problem!",
    image: "/img/ilu/cherry/.png",
  },*/
];

const OG_DEFAULT = "https://qualitygpts.com/img/gpt/landing/chatbot1.png";

export default {
  name: "Home",
  components: {
    // qrform, //
    waitlist,
    // appStoreBtns,
    //  guide,
    //promoMenu,
    //BotList,
    // promoBlock,
    // pricing,
    //  HelloWorld,
    // projCard,
    pricing,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "QualityGPTs • The agency for ChatGPT dev 🤖", ///the platform for AI cretors
    // all titles will be injected into this template
    // titleTemplate: "%s  🍔  ",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      //  { property: "og:site_name", content: "OnlyBots" },
      { property: "og:type", content: "website" },
      { property: "og:image", vmid: "og:image", content: OG_DEFAULT }, //hid props act as unique id
      { property: "twitter:image", content: OG_DEFAULT },
      { property: "twitter:card", content: "summary_large_image" },
    ],
    //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
  data() {
    return {
      bots: [],
      selectedBotCat: 0,
      botCats: [],
      julyFeatures,
      keyFeatures,
      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  mounted() {
    var promo = {
      handle: "Add yours",
      avatar: "/img/bots/plus.webp",
      bio: "Design your own bot, and share it with the world", // "Launch a bot for your brand, or just for fun.   ",
    };
    /*
     */

    window.API.getFeaturedBots({ brochureHome: 12, format: "list" }).then((proj) => {
      //this.bots = proj;
      this.bots = proj;
      this.bots.push(promo);
      this.loadingBots = false;
      window.prerenderReady = true;
      // console.log("PROJJ!", proj, proj.id);
    });
    /*
    window.API.getFeaturedBots({ brochureHome: 1, format: "audiences" }).then((proj2) => {
      //this.bots = proj;
      this.botCats = proj2;
      //  this.bots.push(promo);
      this.loadingBots = false;
      window.prerenderReady = true;
      // console.log("PROJJ!", proj, proj.id);
    });
    */
  },
  methods: {
    scrollToDiv() {
      const element = document.getElementById("pricing");
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style scoped>
div,
p {
  line-height: 1.4em;
}
strong {
  font-weight: bold;
}
.container.c1200 {
  max-width: 1100px !important ;
}

.label {
  opacity: 0.3;
  letter-spacing: 1px;
  font-weight: 200;
}
.hero.is-main {
  background-color: #167df0;
  color: #fff;
  background: linear-gradient(150deg, #ffc92f, #ff185c, #f316d5, #b81ed2);

  background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);

  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
}

/* mobile smaller title and p text size */
@media screen and (max-width: 768px) {
  /* heros */
  .hero.is-main {
    background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);
  }

  .title.is-2 {
    font-size: 1.8rem !important;
  }
  .subtitle.is-4 {
    font-size: 1.1rem !important;
  }
}

@media (max-width: 767px) {
  /* <== You can change this break point as per your  needs */
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media (min-width: 767px) {
  /* <== You can change this break point as per your  needs */
  .reverse-columns-desktop {
    flex-direction: column-reverse;
    flex-direction: row-reverse;
    display: flex;
  }
}

.alterGrid img {
  margin: auto;
  display: block;
}

.content .icon.is-large {
  font-size: 2.8rem;
  margin-bottom: 10px;
  float: right;
}

.cardCity {
  /*
  border-radius: 10px;
  border: 2px solid transparent;
  */
}
.cardCity p.desc {
  line-height: 1.6em;
}
.cardCity a {
  color: #000;
}

.title.label {
  text-transform: uppercase;
}
/*
.cardCity:not(.muted):hover,
.cardCity:not(.muted):hover a .icon,
.cardCity:not(.muted):hover a .title {
  border-color: blue;
  color: blue;
}*/
</style>
